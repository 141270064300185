var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                align: "center",
                justify: "center",
                cols: "12",
                sm: "8",
              },
            },
            [
              _c(
                "v-col",
                { attrs: { align: "start", justify: "start" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.redirectBack },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "secondary white--text text-h5" } },
                    [_vm._v(" Create a new manual ")]
                  ),
                  _c("v-text-field", {
                    staticClass: "mt-5",
                    attrs: { dense: "", outlined: "", label: "Manual name" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      counter: "100",
                      outlined: "",
                      label: "Description",
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", plain: "" },
                          on: { click: _vm.redirectBack },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.api.isLoading,
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }